import React from 'react';
import { ImagesSt } from './styles';
import Button from '../../components/Button';
import { PrinceAbout } from '../../GlobalStyles';
import Social from '../../components/Social';
import jef from '../../assets/teste.png';
import ivan from '../../assets/ivan.png';
import elvis from '../../assets/elvis.png';
import mulher from '../../assets/mulher.png';

export default function Images() {
  return (
    <ImagesSt className="center">
      <h3>Pessoas que conseguiram atrair mais clientes com as ações do E-BOOK</h3>

      <div className="box1">
        <Social img={elvis} name="Elvis Martins" sub="Ceo, Supermercados Martins" text="A partir do momento que mudamos nossa forma de ver o marketing em nossa empresa, através das estratégias implementadas  pelo Cléber nas redes sociais, tivemos uma grande virada de chave com relação as vendas e a credibilidade dos nossos clientes, pudemos mostrar nossa essência e valores e como podemos impactar a região que estamos inseridos por meio de pequenas ações, ganhamos muita notoriedade no setor, visibilidade dos nossos clientes e até de possível investidores, após essa nossa mudança de posicionamento, trabalhando com o marketing de forma bem humorada." verf />
      </div>

      <div className="box2">
        <Social img={jef} name="Jefferson Mendes" sub="Gestor de Marketing " text="Ações que mudaram minha visão de como fazer uma campanha de marketing, formas simples de executar porém com um resultado muito bom, ja estamos vendo as vendas aumentarem com as ações, estamos adaptando para nosso seguimento e vem dando resultamos satisfatórios." />
      </div>

      <div className="box1">
        <Social img={mulher} name="Milena Farias" sub="Diretora de Marketing AGP" text="Eu quero agradecer pelo E-BOOK, foram executadas aqui na AGP 02 ações do MARKETING DE MILHÕES e tivemos um aumento de 20% em cada campanha. Já estamos preparando a próxima! +) " verf />
      </div>

      <div className="box2">
        <Social img={ivan} name="Ivan Martins" sub="Diretor Financeiro " text="Tivemos um aumento astronômico com relação aos nossos números nas redes sociais, após aderirmos as estratégias do Cléber em nosso departamento de Marketing, hoje estamos colhendo os frutos, já tivemos muitas oportunidades de negócios que surgiram através do nosso alcance nas redes sociais, muita parceria com indústrias, temos orgulho de dizer que somos uma pequena rede do interior do Paraná, que hoje tem seguidores engajados no Brasil inteiro, e em alguns lugares do mundo 🙌🏻" />
      </div>

      <Button />

      <PrinceAbout>
        De:
        {' '}
        <t>R$ 97,00</t>
        {' '}
        por GRÁTIS!
      </PrinceAbout>
    </ImagesSt>
  );
}
