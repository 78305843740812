import styled from 'styled-components';

export const FormSt = styled.form`
  padding: 20px 0;

  p{
    text-align: center;
  }

  h2{
    font-size: 28px;
    margin-top: 15px;
    color: ${(props) => props.theme.orange};
  }
`;

export const GroupInput = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  label{
    font-size: 16px;
    margin-bottom: 10px;
  }

  input{
    font-size: 16px;
    padding: 7px 5px;
    border-radius: 0.2rem;
    border: 1px solid ${(props) => props.theme.green};
  }
`;

export const ButtonSubmit = styled.button`
  width: 100%;
  max-width: 300px;
  padding: 13px;
  margin-top: 35px;
  text-align: center;
  color: ${(props) => props.theme.textLigth};
  font-size: 18px;
  font-weight: 900;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  background-color: ${(props) => props.theme.orange};
`;
