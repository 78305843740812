import styled from 'styled-components';

export const HeaderSt = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  background-color: ${(props) => props.theme.green};

  h2{
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.purple};

    b{
      margin-left: 5px;
      text-transform: uppercase;
    }
  }

  svg{
    font-size: 20px;
    margin-left: 2px;
    color: ${(props) => props.theme.purple};
  }

  img{
    width: 100%;
    max-width: 32px;
    margin-left: 5px;
  }
`;
