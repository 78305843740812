import styled from 'styled-components';

export const InfoSt = styled.section`
  margin-top: 50px;

  h3{
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
  }

  img{
    width: 100%;
    margin: 20px 0;
    max-width: 180px;
    height: 270px;
    object-fit: contain;
  }

  h2{
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 35px;
  }
`;
