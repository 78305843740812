import React from 'react';
// import { GoCheckCircle } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import img from '../../assets/logo.png';
import { Range, SendSt } from './styles';

export default function Send() {
  return (
    <SendSt>
      <div className="center">
        <Link to="/">
          <FaArrowLeftLong />
        </Link>

        <img src={img} alt="logo" />

        <p>Quase concluído</p>
        <Range />

        <h2>Parabéns!!!</h2>

        <span>Aguarde que o conteúdo chegará no seu email cadastrado</span>

        <h6>verificar caixa entrada principal e SPAM</h6>

        <a href="https://chat.whatsapp.com/LuDMOFasAVwFtVkToDFWhv" className="whatsapp">Entre para o nosso grupo vip!</a>

        {/* <GoCheckCircle className="sucess" /> */}

      </div>
    </SendSt>
  );
}
