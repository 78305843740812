import styled from 'styled-components';

export const BottomSt = styled.section`
  margin-top: 25px;
  padding: 10px 5%;

  img{
    width: 100%;
    max-width: 500px;
    height: 180px;
    object-fit: cover;
  }
`;
