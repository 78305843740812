import styled from 'styled-components';

export const AboutSt = styled.section`
  margin-top: 35px;
  
  h2{
    font-size: 22px;
    text-align: center;
    font-weight: 400;
  }

  p{
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    margin-top: 30px;
  }
`;
