import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { ListSt } from './styles';
import Button from '../../components/Button';

export default function List() {
  return (
    <ListSt className="center">
      <h2>Perfeito para você que: </h2>

      <ul>
        <li>
          <FaCheck color="#ffffff" />
          Quer atrair mais clientes.
        </li>

        <li>
          <FaCheck color="#ffffff" />
          Apreender a planejar e executar.
        </li>

        <li>
          <FaCheck color="#ffffff" />
          Ter mais resultados com suas ações de marketing.
        </li>

        <li>
          <FaCheck color="#ffffff" />
          Processo criativo ilimitado
        </li>
      </ul>

      <Button />
    </ListSt>
  );
}
