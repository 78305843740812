import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonSt } from './styles';

export default function Button() {
  return (
    <ButtonSt>
      <Link to="/form">Quero meu e-book</Link>
    </ButtonSt>
  );
}
