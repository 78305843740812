import React from 'react';
import { IoIosStar } from 'react-icons/io';
import PropTypes from 'prop-types';
import * as styles from './styles';

export default function Social({
  img, name, sub, text, verf,
}) {
  console.log(verf);

  return (
    <styles.SocialSt $isVerif={verf !== undefined}>

      <styles.SocialGroup $isVerif={verf !== undefined}>

        <styles.SocialImg $isVerif={verf !== undefined}>
          <img src={img} alt="foto" />
        </styles.SocialImg>

        <styles.SocialName $isVerif={verf !== undefined}>
          <h2>{name}</h2>
          <p>{sub}</p>

          <div>
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
          </div>
        </styles.SocialName>

      </styles.SocialGroup>

      <styles.SocialText>
        {text}
      </styles.SocialText>

    </styles.SocialSt>
  );
}

Social.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  verf: PropTypes.string.isRequired,
};
