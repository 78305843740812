import styled from 'styled-components';

export const DescriptionSt = styled.section`
  margin-top: 45px;
  
  p{
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 40px;

    b{
      color: ${(props) => props.theme.green};
      text-transform: uppercase;
    }
  }
`;
