import React from 'react';
import { DescriptionSt } from './styles';
import Button from '../../components/Button';
import { PrinceAbout } from '../../GlobalStyles';

export default function Description() {
  return (
    <DescriptionSt className="center">
      <p>
        Esse E-BOOK vai te ensinar
        {' '}
        <b>30 ações de Marketing para sua empresa</b>
        {' '}
        que já foram validadas no supermercado que mais se destaca hoje no instagram de todo Brasil, estamos falando do Supermercado Martins.
        {' '}
        <b>São mais de 05 milhões de pessoas alcançadas todos mês.</b>
        {' '}
        Reels com mais de 12 Milhões de visualizações
      </p>

      <Button />

      <PrinceAbout>
        De:
        {' '}
        <t>R$ 97,00</t>
        {' '}
        por GRÁTIS!
      </PrinceAbout>
    </DescriptionSt>
  );
}
