import React from 'react';
import { BottomSt } from './styles';
import img from '../../assets/footer.png';

export default function Bottom() {
  return (
    <BottomSt className="center">
      <img src={img} alt="footer" />
    </BottomSt>
  );
}
