import styled from 'styled-components';

export const SocialSt = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  padding: 0 5px;
  border-radius: 3.5rem;
  background-color: ${(props) => props.theme.textLigth};
  margin-top: 40px;
  margin-bottom: 30px;

  &::after{
    content: "";
    position: absolute;
    transform: ${(props) => (props.$isVerif ? 'rotate(90deg)' : 'rotate(0deg)')};
    border-left: 30px solid ${(props) => props.theme.textLigth};
    border-right: 30px solid transparent;
    border-top: 30px solid ${(props) => props.theme.textLigth};
    border-bottom: 30px solid transparent;
    left: ${(props) => (props.$isVerif ? '20px' : '')};
    right: ${(props) => (props.$isVerif ? '' : '20px')};
    bottom: -45px;
  }
`;

export const SocialGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.$isVerif ? 'row' : 'row-reverse')};
`;

export const SocialImg = styled.div`
  width: 140px;
  height: 110px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -20px;
  left: ${(props) => (props.$isVerif ? '-35px' : '35px')};
  padding: 6px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.textLigth};

  img{
    width: 100%;
    max-width: 100px;
    border-radius: 100%;
    border: 4px solid  ${(props) => (props.$isVerif ? `${props.theme.orange}` : `${props.theme.green}`)};

  }
`;

export const SocialName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$isVerif ? 'flex-start' : 'flex-end')};
  margin-left: ${(props) => (props.$isVerif ? '-30px' : '0px')};
  margin-right: ${(props) => (props.$isVerif ? '0px' : '-30px')};
  width: 100%; 
  padding: 10px 0;
  color: ${(props) => props.theme.textDark};

  h2{
    font-size: 18px;
  }

  p{
    font-size: 16px;
  }

  svg{
    font-size: 16px;
    margin-top: 5px;
    color: ${(props) => (props.$isVerif ? `${props.theme.orange}` : `${props.theme.green}`)};
  }

`;

export const SocialText = styled.p`
  padding: 0px 20px 25px 20px;
  margin-top: -15px;
  font-size: 16px;
  color: ${(props) => props.theme.textDark};
`;
