import styled from 'styled-components';

export const ListSt = styled.section`
  margin-top: 45px;

  h2{
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 10px;
  }

  ul{
    width: 100%;
    list-style-type: none;
    margin-bottom: 40px;

    li{
      position: relative;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-bottom: 10px;
      margin-top: 20px;
      color: ${(props) => props.theme.green};

      svg{
        margin-right: 10px;
      }

      &::after{
        position: absolute;
        bottom: 0;
        left: 0;
        content: ' ';
        width: 50%;
        height: 1px;
        background-color: ${(props) => props.theme.textLigth};
      }
    }
  }
`;
