import styled from 'styled-components';

export const TopSt = styled.section`

  img{
    width: 100%;
    max-width: 180px;
    height: 270px;
    object-fit: contain;
    margin-top: 20px;
  }

  h2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 500;
    color: ${(props) => props.theme.green};

    b{
      font-weight: 800;
    }
  }

  p{
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
`;
