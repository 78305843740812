import React from 'react';
import { AboutSt } from './styles';

export default function About() {
  return (
    <AboutSt className="center">
      <h2>
        O que é o
        {' '}
        <b>e-book 30 ações de marketing para sua empresa?</b>
      </h2>

      <p>
        É para quem busca melhorar o marketing do seu empreendimento.
      </p>

      <p>Estratégias para desenvolver ações de marketing que já foram utilizadas e validadas, basta você adaptar para sua realidade e colher os resultados.</p>
    </AboutSt>
  );
}
