import styled from 'styled-components';

export const ButtonSt = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a{
    width: 100%;
    max-width: 600px;
    text-decoration: none;
    background-color: ${(props) => props.theme.green};
    border-radius: 0.5rem;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    padding: 12px 10px;
    color: ${(props) => props.theme.purple};

    -webkit-box-shadow: 0px 0px 19px 0px rgba(256, 256, 256, 0.4);
    -moz-box-shadow: 0px 0px 19px 0px rgba(256, 256, 256, 0.4);
    box-shadow: 0px 3px 10px 0px rgba(256, 256, 256, 0.4);
  }
  `;
