import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html{
    height: 100%;
  }

  html,body, #root{
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.purple};
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: ${(props) => props.theme.textLigth};
  }

  .center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 5%;
  }
`;

export const PrinceAbout = styled.h2`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;

  t{
    text-decoration: line-through;
    text-decoration-color: ${(props) => props.theme.green};
  }
`;
