import styled from 'styled-components';

export const ImagesSt = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .box1{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .box2{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  h3{
    font-size: 22px;
    text-align: center;
  }

  a{
    margin-top: 35px;
  }
`;
