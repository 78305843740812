import React from 'react';
import { PersonalSt } from './styles';
import Button from '../../components/Button';
import { PrinceAbout } from '../../GlobalStyles';

import img from '../../assets/foto.png';

export default function Personal() {
  return (
    <PersonalSt className="center" $isImg={img}>
      <div className="img_background" />

      <h3>Cleber</h3>
      <span>Marketeiro</span>

      <p>Diretor de Marketing do Supermercado Martins, o maior perfil de supermercados do Instagram no Brasil, são mais de 245k seguidores, na página e um impressioante alcance de mais de 16M de contas todo mês.</p>

      <Button />

      <PrinceAbout>
        De:
        {' '}
        <t>R$ 97,00</t>
        {' '}
        por GRÁTIS!
      </PrinceAbout>
    </PersonalSt>
  );
}
