import styled from 'styled-components';

export const SendSt = styled.section`
  padding: 20px;

  > .center{
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2{
    font-size: 22px;
    text-align: center;
    margin: 25px 0 15px 0;
  }

  p{
    font-weight: 300;
    margin-top: 25px;
    font-size: 16px;
    margin-bottom: 7px;
  }

  h6{
    font-size: 15px;
    margin-top: 5px;
    font-weight: 400;
  }

  .sucess{
    font-size: 100px;
    margin-top: 30px;
    color: ${(props) => props.theme.green};
  }

  span{
    text-align: center;
    font-size: 18px;
  }

  .whatsapp{
    font-size: 16px;
    font-weight: 700;
    padding: 12px 8px;
    border-radius: 0.5rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    margin-top: 35px;
    width: 100%;
    background-color: ${(props) => props.theme.orange};
  }

  a{
    font-size: 26px;
    color: ${(props) => props.theme.textLigth};
    margin-right: auto;
  }

  img{
    width: 100%;
    max-width: 200px;
  }

  .hidden{
    display: none;
  }
`;

export const Range = styled.div`
  width: 100%;
  height: 19px;
  border-radius: 5rem;
  background-color: ${(props) => props.theme.textLigth};
  border: 1px solid ${(props) => props.theme.green};
  position: relative;

  &::after{
    position: absolute;
    content: '85%';
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    width: 85%;
    height: 102%;
    border-radius: 5rem 0 0 5rem;
    background-color: ${(props) => props.theme.green};
  }

`;
