import React from 'react';
import { InfoSt } from './styles';
import Button from '../../components/Button';

import img from '../../assets/ebok.png';

export default function Info() {
  return (
    <InfoSt className="center">
      <h3>Totalmente GRÁTIS</h3>

      <img src={img} alt="foto" />

      <h2>E-book 30 ações de marketing para sua empresa</h2>

      <Button />
    </InfoSt>
  );
}
