import React from 'react';
import { TopSt } from './styles';
import img from '../../assets/ebok.png';

export default function Top() {
  return (
    <TopSt className="center">
      <img src={img} alt="ebook" />

      <h2>
        <b>Marketing</b>
        {' '}
        de milhões
      </h2>

      <p>Do zero a milhões: O caminho para ter estratégias que vão vender e engajar mais clientes com seu negócio!</p>
    </TopSt>
  );
}
