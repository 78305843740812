import React from 'react';
import { MdOutlineTimer } from 'react-icons/md';
import { HeaderSt } from './styles';

export default function Header() {
  return (
    <HeaderSt>
      <h2>
        Totalmente
        <b>Gratuito</b>
      </h2>

      <MdOutlineTimer />
    </HeaderSt>
  );
}
