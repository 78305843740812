import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalStyles } from './GlobalStyles';
import { defaultTheme } from './theme/styles';
import Home from './pages/Home';
import Form from './pages/Form';
import Send from './pages/Send';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<Form />} />
          <Route path="/send" element={<Send />} />
        </Routes>
      </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;
