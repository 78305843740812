import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from './styles';

export default function Form() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [button, setButton] = useState('Enviar');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name !== '' && email !== '' && phone !== '') {
      setButton('Enviando');
      try {
        const response = await fetch('https://gmail.us21.list-manage.com/subscribe/post?u=13019d28f16af0e641c360eaa&id=f5cf9e622c&f_id=00cf78e1f0', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            FNAME: name,
            EMAIL: email,
            PHONE: phone,
          }),
        });

        if (response.ok) {
          navigate('/send');
        } else {
          const errorMessage = await response.text();
          console.error('aqui1', errorMessage);
        // Lidar com erros, como exibir uma mensagem de erro ao usuário.
        }
      } catch (error) {
        navigate('/send');
      // Lidar com erros, como exibir uma mensagem de erro ao usuário.
      }
    } else {
      alert('Preencha todos os campos');
      setButton('Enviar');
    }
  };

  return (
    <styles.FormSt action="https://gmail.us21.list-manage.com/subscribe/post?u=13019d28f16af0e641c360eaa&amp;id=f5cf9e622c&amp;f_id=00cf78e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="" onSubmit={handleSubmit}>
      <div className="center">
        <p>Preencha seus dados corretamente para garantir que vai receber o material.</p>
        <h2>100% GRATUITO</h2>

        <styles.GroupInput>
          {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
          <label htmlFor="name">Insira seu nome</label>
          <input type="text" name="FNAME" placeholder="Digite seu nome" value={name} onChange={(e) => setName(e.target.value)} />
        </styles.GroupInput>

        <styles.GroupInput>
          {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
          <label htmlFor="name">Insira seu melhor email</label>
          <input type="text" name="EMAIL" id="name" placeholder="seumelhoremail@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </styles.GroupInput>

        <styles.GroupInput>
          {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
          <label htmlFor="name">Insira seu número</label>
          <input type="text" name="PHONE" id="name" placeholder="(99) 99999-9999" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </styles.GroupInput>

        {/* <div aria-hidden="true"><input type="text" name="b_13019d28f16af0e641c360eaa_f5cf9e622c" tabIndex="-1" value="" /></div> */}

        <styles.ButtonSubmit type="submit" name="subscribe">{button}</styles.ButtonSubmit>

      </div>
    </styles.FormSt>

  );
}
