import React from 'react';
import Top from '../../layout/Top';
import Description from '../../layout/Description';
import About from '../../layout/About';
import List from '../../layout/List';
import Info from '../../layout/Info';
import Personal from '../../layout/Personal';
import Images from '../../layout/Images';
import Bottom from '../../layout/Bottom';
import Header from '../../layout/Header';

export default function Home() {
  return (
    <>
      <Header />
      <Top />
      <Description />
      <About />
      <List />
      <Info />
      <Personal />
      <Images />
      <Bottom />
    </>
  );
}
