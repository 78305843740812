import styled from 'styled-components';

export const PersonalSt = styled.section`
  margin-top: 50px;

  .img_background{
    position: relative;
    background-image: url(${(props) => props.$isImg});
    background-position: center;
    background-size: cover;
    width: 100%;
    max-width: 350px;
    height: 350px;
    border-radius: 2.5rem;
    background-color: red;

    &::after{
      position: absolute;
      content: '';
      background-color: ${(props) => props.theme.green};
      width: 200px;
      height: 14px;
      bottom: -7px;
      left: calc(50% - 100px);
    }
  }

  h3{
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
  }

  span{
    font-size: 16px;
    text-transform: uppercase;
  }

  p{
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 40px;
  }
`;
